import '../labReport/labReport.css';
import { TbReport } from "react-icons/tb";
import { WhatsApp } from '@mui/icons-material';
import LabRPTone from '../assets/images/sanjeevaniUHT.pdf';
const LabReport = () => {

    return (
        <>

            <div className='fix'>
                <a href={LabRPTone} target='_blank'>
                <TbReport title='Lab Report' style={{color:'green',fontSize:'40px' }}/>
                </a>
                
            </div>

           
            <div className='whatUpIcon'>
                <a href="https://api.whatsapp.com/send?phone=+917830483495&text=Hello%20Barsana%20Magic"> 
                <WhatsApp style={{fontSize:'50px'}}/>
                </a>
            </div>


        </>
    )
}

export default LabReport





