import '../loading/loading.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LoadingIcon from '../assets/images/lodingIcon.png';

const Loading = () => {
    return (

        <div className='loading'>

            <Box sx={{ display: 'flex', gap:'15px'}}>
                <CircularProgress color="success" />
                {/* <h4>Barsana Magic</h4> */}
                <img src={LoadingIcon}/>
            </Box>
        </div>

    );
}

export default Loading;