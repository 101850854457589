import '../subscribe/subscribe.css';
import SubscribeBanner from '../assets/images/subscribe.png';
import Delivery from '../assets/images/banner-9-min.png';
import AppImg from '../assets/images/appMobile.png';
import InfoGraphic from '../assets/images/new-infographic-banner.png'
import { useState } from 'react';
const Subscribe = () => {
    const [email,setEmail]=useState();



    return (
        <div className='newsLetter'>
            <div className='newsLetterImage'>
                <img src={InfoGraphic} alt='' />


                {/* <div className='newLetterText'>
                    Stay home & get your daily<br />
                    needs from our shop
                </div> */}
                
                {/* <div className='emailBtn'>
                    <input type='email' className='form-control p-3' placeholder='Your email address' value={email}
                    onChange={(e)=>setEmail(e.target.value)}/>
                    <button className='btnSend'>subscribe</button>
                </div> */}
            </div>

            {/* <div className='newLetterbanner'>
                <img src={AppImg} alt='' />
            </div> */}

        </div>
    )
}
export default Subscribe;