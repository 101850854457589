import '../footer/footer.css'
import BrandLogoFooter from '../assets/images/barsanamagic.png'
import GooglePlay from '../assets/images/android.webp';
import Iphone from '../assets/images/ios-icon.webp';
import PaymentMethod from '../assets/images/payment-method.png';
import IconOne from '../assets/images/icon-1.png';
import IconTwo from '../assets/images/icon-2.png';
import IconThree from '../assets/images/icon-3.png';
import IconFour from '../assets/images/icon-4.png';
import IconFive from '../assets/images/icon-5.png';

import { FaPhoneAlt } from "react-icons/fa";

import { FaFacebook } from "react-icons/fa6";
import { SiYoutubemusic } from "react-icons/si";
import { FaSquareInstagram } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";

import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {
    return (

        <div className='container-fluid'>
            <div className='row'>
                <div className='f-section'>
                    <div className='ticketSection'>
                        <div className='ticket'>
                            <div className='banner-img'>
                                <img src={IconOne} alt='' />
                            </div>
                            <div className='banner-text'>
                                <h5>Longer Self Life</h5>
                                <p>Food Products Packed With UHT Technology </p>
                            </div>
                        </div>

                        <div className='ticket'>
                            <div className='banner-img'>
                                <img src={IconTwo} alt='' />
                            </div>
                            <div className='banner-text'>
                                <h5>&nbsp;Certificate</h5>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;trusted quality certificate all</p>
                            </div>
                        </div>
                        <div className='ticket'>
                            <div className='banner-img'>
                                <img src={IconThree} alt='' />
                            </div>
                            <div className='banner-text'>

                                <h5>Products</h5>
                                <p>Health and Wellness Products Manufacturers</p>
                            </div>
                        </div>
                        <div className='ticket'>
                            <div className='banner-img'>
                                <img src={IconFour} alt='' />
                            </div>
                            <div className='banner-text'>
                                <h5>Promo Code</h5>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BARSANAM20 get 20% discount</p>
                            </div>
                        </div>

                        {/* <div className='ticket'>
                            <div className='banner-img'>
                                <img src={IconFive} alt='' />
                            </div>
                            <div className='banner-text'>
                                <h5>Easy returns</h5>
                                <p>Within 30 days</p>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='f-last'>
                    <div className='f-outter'>
                        <img src={BrandLogoFooter} className='footerBrandLogo'/>
                        {/* <h6>Sanjeevani Agrofoods Limited</h6> */}

                        <p className='companyAddress'>
                            <MdLocationOn style={{ color: 'var(--color-brand)', fontSize: '30px' }} />  <address> KHASRA NO. 148 CHA MAUZA
                                CHANDRABANI KHALSA,
                                Mohabbewala Industrial Area,
                                Dehradun, Uttarakhand,
                                Pin Code-248002</address>
                            <FaPhoneAlt style={{ color: 'var(--color-brand)' }} />  +91-7830483495<br />
                            <MdOutlineEmail style={{ color: 'var(--color-brand)' }} />  support@sanjeevaniagrofoods.com
                        </p>


                    </div>

                    <div className='f-outter'>
                        <h1>Popular</h1>
                        <ul>
                            <li> <a href='https://www.barsanamagic.com/products/details/organic-a2-badri-cow-ghee-500-ml'> Organic Badri Cow Ghee</a></li>
                            <li><a href='https://www.barsanamagic.com/products/details/organic-bilona-cow-ghee-500ml'>Organic Bilona Cow Ghee</a></li>
                            <li> <a href='https://www.barsanamagic.com/products/details/organic-cow-ghee-500ml'>Organic Cow Ghee</a></li>
                            <li> <a href='https://www.barsanamagic.com/products/details/organic-wild-forest-honey-300gms'> Organic Raw Wild Forest Honey</a></li>
                            <li><a href='https://www.barsanamagic.com/products/details/natural-acacia-honey-300gms'> Natural Raw Acacia Honey</a></li>
                            <li><a href='https://www.barsanamagic.com/products/details/natural-neem-honey-300gms'> Natural Raw Neem Honey</a></li>
                        </ul>
                    </div>
                    <div className='f-outter'>
                        <h1>Company</h1>
                        <ul>
                            <li><a href='https://www.barsanamagic.com/home/return-policy'> Return Policy</a></li>
                            <li><a href='https://www.barsanamagic.com/home/shipping-policy'>Shipping Policy</a></li>
                            <li><a href='https://www.barsanamagic.com/products'><li>Products</li></a></li>
                            <li><a href='https://www.barsanamagic.com/home/terms-and-conditions'>Terms & Condition</a></li>
                            <li><a href='https://www.barsanamagic.com/home/privacy-policy'>Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div className='f-outter'>
                        <h1>Install App</h1>
                        <p>From App Store or Google Play</p>
                        <a href='https://play.google.com/store/apps/details?id=com.barsanamagic.user ' target='_blank'>
                            <img className='playStoreImg' src={GooglePlay} alt='download app from google play store' />
                        </a>

                        <a href='https://apps.apple.com/in/app/barsana-magic-customer/id6503254863' target='_blank'>
                            <img className='playStoreImg' src={Iphone} alt='download app' />
                        </a>

                        <h6 className='mt-4'>Secured Payment Gateways</h6>
                        <img src={PaymentMethod} alt='payment method' />
                    </div>
                </div>

            </div>
            <div className='line'>

            </div>
            <div className='footerLast'>
                <div className='stripLeft'>
                    Copyright © 2024, All Right Reserved
                </div>

                <div className='stripRight'>
                    <h5>Follow Us</h5>
                    <a href='https://www.facebook.com/people/Barsana-magic/61558491883872/' target='_blank'>
                        <FaFacebook style={{ color: 'var(--color-brand)' }} />
                    </a>
                    <a href='https://www.youtube.com/@BarsanaMagicoffical' target='_blank'>
                        <SiYoutubemusic style={{ color: 'var(--color-brand)' ,width:'100%'}} />
                    </a>

                    <a href='https://www.instagram.com/barsanamagic/?igsh=MTRsbzI0bXhyZGp2MQ%3D%3D' target='_blank'>
                        <FaSquareInstagram style={{ color: 'var(--color-brand)' }} />
                    </a>

                    <a href='https://x.com/i/flow/login?redirect_after_login=%2Fsanjeevani2024' target='_blank'>
                        <FaXTwitter style={{ color: 'var(--color-brand)' }} />
                    </a>

                </div>
            </div>



        </div>
    );
}

export default Footer;