import '../allProduct/allProduct.css';
import FullImage from '../assets/images/allPrdBanner.jpeg';
import CowMilk from '../assets/images/yellow-milk.png';
import Honey from '../assets/images/jamun.png';


import BilonaGhee from '../assets/images/bilona.png'
import HealthBooster from '../assets/images/health-booster.png'
import ImmunityBooster from '../assets/images/immunity-booster.png'
import OrganicGhee from '../assets/images/organic.png'
// import Turmeric from '../assets/images/turmeric.png'
import Nettle from '../assets/images/nettle.png'
import Badri from '../assets/images/badri.png'


import MustardHoney from '../assets/images/mustard.png'
import NeemHoney from '../assets/images/neem.png'
import VanTulsi from '../assets/images/van-tulsi.png'
import WildForest from '../assets/images/wild-forest.png'
import Acacia from '../assets/images/acacia.png'
import Jamun from '../assets/images/jamun.png'

import StayFit from '../assets/images/stay-fit.png'
import GrowFast from '../assets/images/grow-fast.png'
import Doubletoned from '../assets/images/double-toned.png'

import DairyProduct from '../assets/images/farming.png'

const AllProduct = (props) => {
    return (<>
        <div className='container-fluid allProductStrip'>
            {props.Heading}
        </div>
        <div className='list-product'>

            <main className='allPrdContainer'>
                <swiper-container slides-per-view="3" space-between="20" mousewheel-invert="true" autoplay="true"
                    breakpoints={
                        JSON.stringify({

                            300: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },

                            486: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 0,
                            },

                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            }

                        })
                    }
                    autoplay-delay="1000">

                    {/* pOneInner */}

                    <swiper-slide>
                        <div className='prd__All p-one'>
                            <div className='p-one-text'>

                                <div className='pOneInner'>
                                    Deal of day<br />10% off
                                </div>
                                <a href='https://www.barsanamagic.com/products'>
                                    <button className='btn btn-success'>Shop Now</button>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>


                    <swiper-slide>
                        <div className='prd__All p-eight'>
                            <div className='p-one-text'>
                                <div className='pOneInner'>
                                Convenient Healthy <br/>Recipe 

                                </div>
                                <a href='https://sanjeevaniagrofoods.com/natural-pure-raw-mustard-honey-soothe-sore-recipe.php'>
                                    <button className='btn btn-success'>Read More</button>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>



                    <swiper-slide>
                        <div className='prd__All p-14'>
                            <div className='p-one-text'>
                                <div className='pOneInner'>
                                Super Food<br/> Breakfast
                                </div>

                                <a href='https://www.barsanamagic.com/products/details/organic-cow-milk-grow-fast200ml'>
                                    <button className='btn btn-success'>Read More</button>
                                </a>

                            </div>

                        </div>
                    </swiper-slide>


                    <swiper-slide>
                        <div className='prd__All p-two'>
                            <div className='p-one-text'>

                                <div className='pOneInner'>
                                    Health and<br /> Wellness
                                </div>
                                <a href='https://sanjeevaniagrofoods.com/health-booster-remedies.php' target='_blank'>
                                    <button className='btn btn-success'>Read More</button>
                                </a>
                            </div>

                            
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='prd__All p-nine'>
                            <div className='p-one-text'>
                                <div className='pOneInner'>
                                    Know Power of<br /> Organic 

                                </div>
                                <a href='https://www.barsanamagic.com/blogs/view_detail/the-rise-of-organic-dairy-products-1' target='__blank'>
                                    <button className='btn btn-success'> Blog</button>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </main>

        </div>



    </>)
}

export default AllProduct