
import '../productVideo/productVideo.css';

import YouTube from 'react-youtube';
const ProductVideo = () => {
    const opts = {
        height: '290',
        width: '510',
        playerVars: {
            autoplay: 1,
        },
    };

    const onReady = (event) => {
        event.target.playVideo(); // Restart the video when it ends
        event.target.mute(); // Mute the video
    };

    const onEnd = (event) => {
        event.target.playVideo(); // Restart the video when it ends
    };

    return (
        <>
            <div className="">
                <div className="mainSection mt-5">
                    <div className="innersection">
                        {/* <div className='videoBanner'>
                            <img src={prdSlider} alt='' />
                        </div> */}
                        <main className='swipperMain'>
                            <swiper-container breakpoints={
                                JSON.stringify({
                                    486: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },

                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },

                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 5,
                                    }
                                })
                            }
                                navigation-next-el=".custom-next-buttoon"
                                navigation-prev-el=".custom-prev-buttoon"
                                pagination-clickable="true"
                                pagination-dynamic-bullets="true"
                                autoplay-delay="2500"
                                autoplay-disable-on-interaction="false"
                                center-slides="true"
                                showThumbnails="true"
                                showFullscreenButton="true"
                                style={
                                    {
                                        "--swiper-pagination-color": "#3BB77E",
                                        "--swiper-pagination-bullet-size": "15px",
                                    }
                                }>



                                <swiper-slide>
                                    <div className="innerVideo">
                                        <YouTube
                                            videoId="mRVYWl8UlOU"
                                            opts={opts}
                                            onReady={onReady}
                                            onEnd={onEnd}
                                        />
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div className="innerVideo">
                                        <YouTube
                                            videoId="afueFvxDEn8"
                                            opts={opts}
                                            onReady={onReady}
                                            onEnd={onEnd}
                                        />
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div className="innerVideo">
                                        <YouTube
                                            videoId="RqafI4_Q9Ug"
                                            opts={opts}
                                            onReady={onReady}
                                            onEnd={onEnd}
                                        />
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div className="innerVideo">
                                        <YouTube
                                            videoId="c8PJnYNRRCY"
                                            opts={opts}
                                            onReady={onReady}
                                            onEnd={onEnd}
                                        />
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div className="innerVideo">
                                        <YouTube
                                            videoId="JII0FH_RgX0"
                                            opts={opts}
                                            onReady={onReady}
                                            onEnd={onEnd}
                                        />
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div className="innerVideo">
                                        <YouTube
                                            videoId="InjEXYMkuXU"
                                            opts={opts}
                                            onReady={onReady}
                                            onEnd={onEnd}
                                        />
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div className="innerVideo">
                                        <YouTube
                                            videoId="mRVYWl8UlOU"
                                            opts={opts}
                                            onReady={onReady}
                                            onEnd={onEnd}
                                        />
                                    </div>
                                </swiper-slide>




                            </swiper-container>

                            {/* <div className="nav-btn custom-prev-buttoon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="main-grid-item-icon" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <line x1="19" x2="5" y1="12" y2="12" />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </div>

                            <div className="nav-btn custom-next-buttoon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="main-grid-item-icon" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <line x1="5" x2="19" y1="12" y2="12" />
                                    <polyline points="12 5 19 12 12 19" />
                                </svg>
                            </div> */}
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductVideo;