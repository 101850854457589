import React from 'react';
import { Link } from "react-router-dom";
import BrandLogo from '../assets/images/brandBarsana.png';
import BrandSanjeevani from '../assets/images/barsanaSanjeevani.png'
import '../navbar/navbar.css';

const Navbar = () => {
    return (
        <>
            <header>
                <nav>
                    <div class="logo"><a href="#"> <img src={BrandLogo} /></a></div>


                    <div class="logo">
                        <a href="https://www.sanjeevaniagrofoods.com"> 
                          <img src={BrandSanjeevani} />
                        </a>
                    </div>

<div></div>

                    <label for="menubrop" class="bartoggle">≡</label>
                    <input type="checkbox" id="menubrop" />


                    <ul class="NavMenu">
                        <li><a href="index.html">Home</a></li>
                        <li><a href="https://www.barsanamagic.com/products">Products</a></li>
                                        {/* <li><a href="javascript:void(0)"><label for="droplist1" class="toggle">Our Service</label></a>
                        <input type="checkbox" id="droplist1"/>
                        <ul>
                            <li><a href="#">Service A</a></li>
                            <li><a href="#">Service B</a></li>
                            <li>
                            <a href="javascript:void(0)"><label for="droplist2" class="toggle">Service C</label></a>
                            <input type="checkbox" id="droplist2"/>
                            <ul>
                                <li><a href="#">Sub Service 1</a></li>
                                <li><a href="#">Sub Service 2</a></li>
                                <li><a href="#">Sub Service 3</a></li>
                                <li><a href="#">Sub Service 4</a></li>
                            </ul>
                            </li>
                            <li><a href="#">Service D</a></li>
                        </ul>
                        </li> */}
                        <li><a href="https://www.barsanamagic.com/sellers">Seller</a></li>
                        <li><a href="https://www.barsanamagic.com/home/contact-us">Contact Us</a></li>
                        <li><a href="https://www.barsanamagic.com/home/faq">FAQs</a></li>
                        <li><a href="https://www.barsanamagic.com/blogs/">Blogs</a></li>
                    </ul>
                   
                </nav>
            </header>

        </>
    )

}
export default Navbar