
import HealthBooster from '../assets/images/health-booster.png'
import React, { useEffect, useRef, useState } from "react";
import '../sectionFour/ProductSlider.css'
import { MdOutlineShoppingCart } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { register } from 'swiper/element/bundle';

import ReactImageMagnifier from 'simple-image-magnifier/react'
import img1 from '../assets/images/1.jpg';

import GheeOne from '../assets/images/1.jpg';


import GheeTwo from '../assets/images/2.jpg';
import GheeThree from '../assets/images/3.jpg';
import GheeFour from '../assets/images/4.jpg';
import GheeFive from '../assets/images/5.jpg';
import GheeSix from '../assets/images/6.jpg';
import GheeSeven from '../assets/images/7.jpg';


import HoneyOne from '../assets/images/8.jpg'
import HoneyTwo from '../assets/images/9.jpg'
import HoneyThree from '../assets/images/10.jpg'
import HoneyFour from '../assets/images/11.jpg'
import HoneyFive from '../assets/images/12.jpg'
import HoneySix from '../assets/images/13.jpg'

import DoubleTond from '../assets/images/doublrTond6.png';
import StayFit from '../assets/images/stayFit24.png';
import StayFitSix from '../assets/images/stayFit6.png';
import GrowFast from '../assets/images/growFast24.png';

import ImageHover from '../assets/images/imgHover.png'

import HoverBadri from '../assets/images/hover_badri.png'
import HoverBillona from '../assets/images/hover_bilona.png'
import HoverHealthBooster from '../assets/images/hover_healtBooster.png'
import HoverImmunity from '../assets/images/hover_immunity.png'
import HoverNettle from '../assets/images/hover_nettle.png'
import HoverOrganic from '../assets/images/hover_organic.png'
import HoverTurmeric from '../assets/images/hover_turmeric.png'
// import MyReactImageMagnify from '../MyReactImageMagnify/MyReactImageMagnify';

import ReactImageMagnify from "react-image-magnify";
import zIndex from '@mui/material/styles/zIndex';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

register();


const ProductSlider = (props) => {

   
    const [showImage, setShowImage] = useState(false)

    const imageRef = useRef(null);
    return (
        <>

            <div className='topStrip'>

                <div className='stripText'>
                    <h2> {props.Title}</h2>
                </div>

            </div>

            <main className='productContainer'>
                <swiper-container space-between="10" slides-per-view="4" style={
                    {
                        "--swiper-navigation-color": 'var(--color-brand)',
                        "--swiper-pagination-color": 'var(--color-brand)'
                    }
                }
                    navigation="true"
                    className="mySwiper"
                    breakpoints={
                        JSON.stringify({
                            300:{
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            486: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },

                            768: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 50,
                            }
                        })
                    }
                >
                    <swiper-slide>
                        <div className='view-prd' >
                            <div className='sale'>
                                Trending
                            </div>
                            <div className='smallImg'>
                                {/* <img src={GheeSeven}/> */}
                                <ReactImageMagnifier
                                    srcPreview={GheeSeven}
                                    srcOriginal={HoverBadri}
                                    width={300}
                                    height={300}
                                />

                            </div>


                            <div className='prd-details text-center'>
                                First User Additonal 10% Off
                                <p>Apply Coupon Code BARSANAM20</p>

                                <div className='prdHeading'>

                                    <h2>Pure Organic Badri Cow Desi Ghee</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar />
                                    </div>

                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377;1341/

                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>1490/</s>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-a2-badri-cow-ghee-500-ml' target='_blank'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                Trending
                            </div>
                            <div className='smallImg'>
                                {/* <img src={GheeOne} /> */}
                                <ReactImageMagnifier
                                    srcPreview={GheeOne}
                                    srcOriginal={HoverImmunity}
                                    width={300}
                                    height={300}
                                />

                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20</p>

                                <div className='prdHeading'>
                                    <h2>Immunity BoosterCow Fat Spread</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar />
                                    </div>


                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377;770/
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>855/</s>
                                        </div>
                                    </div>



                                </div>
                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-immunity-booster-milk-fat-spread-500ml' target='_blank'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                Trending
                            </div>
                            <div className='smallImg'>
                                {/* <img src={GheeTwo} /> */}
                                <ReactImageMagnifier
                                    srcPreview={GheeTwo}
                                    srcOriginal={HoverOrganic}
                                    width={300}
                                    height={300}
                                />

                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>

                                <div className='prdHeading'>
                                    <h2>Pure Organic Cow Desi Ghee</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 770/<br />
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>855/</s><br />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-cow-ghee-500ml'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button></a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={GheeThree} /> */}
                                <ReactImageMagnifier
                                    srcPreview={GheeThree}
                                    srcOriginal={HoverBillona}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>


                                <div className='prdHeading'>
                                    <h2>Pure Organic Bilona Cow Desi Ghee</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 770/<br />
                                        </div>

                                        <div style={{ color: 'red' }}>
                                            <s>855</s>/<br />
                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div className='buyNow'>
                                <a href="https://www.barsanamagic.com/products/details/organic-bilona-cow-ghee-500ml">
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={GheeFour} /> */}
                                <ReactImageMagnifier
                                    srcPreview={GheeFour}
                                    srcOriginal={HoverNettle}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>

                                <div className='prdHeading'>
                                    <h2>Organic Nettle Cow Desi Fat Spread</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>

                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 770/<br />
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>855/</s><br />
                                        </div>
                                    </div>


                                </div>


                            </div>
                            <div className='buyNow'>
                                <a href="https://www.barsanamagic.com/products/details/organic-nettle-milk-fat-spread-500ml">
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={GheeFive} /> */}
                                <ReactImageMagnifier
                                    srcPreview={GheeFive}
                                    srcOriginal={HoverTurmeric}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>

                                <div className='prdHeading'>
                                    <h2>Organic Turmeric Cow Desi Fat Spread</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>

                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 770/<br />

                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>855/</s><br />
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className='buyNow'>
                                <a href="https://www.barsanamagic.com/products/details/organic-turmeric-milk-fat-spread-500ml">
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>


                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={GheeSix} /> */}
                                <ReactImageMagnifier
                                    srcPreview={GheeSix}
                                    srcOriginal={HoverHealthBooster}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>

                                <div className='prdHeading'>
                                    <h2>Health Booster Cow Fat Spread</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar />
                                    </div>

                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 770/<br />
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>855</s><br />
                                        </div>
                                    </div>

                                </div>

                            </div>



                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-health-booster-milk-fat-spread-500ml'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>



                    {/* ghee section end */}
                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={HoneyOne} /> */}
                                <ReactImageMagnifier
                                    srcPreview={HoneyOne}
                                    srcOriginal={ImageHover}
                                    width={300}
                                    height={300}
                                />

                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20</p>

                                <div className='prdHeading'>
                                    <h2>Natural Pure Mustard Raw Honey</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 310/<br />
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>345/</s><br />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/natural-mustard-honey-300gms'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={HoneyTwo} /> */}
                                <ReactImageMagnifier
                                    srcPreview={HoneyTwo}
                                    srcOriginal={ImageHover}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>

                                <div className='prdHeading'>
                                    <h2>Natural Pure Van-Tulsi Raw Honey</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 310/<br />
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>345/</s><br />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/natural-van-tulsi-raw-honey-holy-basil-300-gms'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={HoneyThree} /> */}
                                <ReactImageMagnifier
                                    srcPreview={HoneyThree}
                                    srcOriginal={ImageHover}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>

                                <div className='prdHeading'>
                                    <h2>Natural Pure Neem Raw Honey</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>

                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 310/
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>345/</s>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/natural-neem-honey-300gms'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={HoneyFour} /> */}
                                <ReactImageMagnifier
                                    srcPreview={HoneyFour}
                                    srcOriginal={ImageHover}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>

                                <div className='prdHeading'>
                                    <h2>Natural Pure Jamun Raw Honey</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 310/
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>345/</s>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/natural-jamun-honey-indian-blackberry-300gms'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={HoneyFive} /> */}
                                <ReactImageMagnifier
                                    srcPreview={HoneyFive}
                                    srcOriginal={ImageHover}
                                    width={300}
                                    height={300}
                                />

                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>
                                <div className='prdHeading'>
                                    <h2>Natural Pure Acacia Raw Honey</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>




                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 310/<br />
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>310/</s><br />
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/natural-acacia-honey-300gms'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={HoneySix} /> */}
                                <ReactImageMagnifier
                                    srcPreview={HoneySix}
                                    srcOriginal={ImageHover}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>
                                <div className='prdHeading'>
                                    <h2>Organic Wild Forest Raw Honey</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>


                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377; 329/<br />
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>365/</s><br />
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-wild-forest-honey-300gms'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    {/* honey end */}

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={DoubleTond} /> */}
                                <ReactImageMagnifier
                                    srcPreview={DoubleTond}
                                    srcOriginal={DoubleTond}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>
                                <div className='prdHeading'>
                                    <h2>Organic Cow Milk Double Toned -1L</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377;1006/

                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>1118/</s>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-cow-milk-double-toned-1-l-pack'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                {/* <img src={StayFitSix} /> */}
                                <ReactImageMagnifier
                                    srcPreview={StayFitSix}
                                    srcOriginal={StayFitSix}
                                    width={300}
                                    height={300}
                                />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>
                                <div className='prdHeading'>
                                    <h2>Organic Cow Milk Stay Fit Milk-1L Pack</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377;1006/

                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>1118/</s>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-cow-milk-stay-fit-milk-1l-pack'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                <img src={StayFit} />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>
                                <div className='prdHeading'>
                                    <h2>Organic Cow Milk Stay Fit Milk-200ML</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377;1006/

                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>1118/</s>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-cow-milk-stay-fit-milk-200ml'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className='view-prd'>
                            <div className='sale'>
                                New
                            </div>
                            <div className='smallImg'>
                                <img src={GrowFast} />
                            </div>
                            <div className='prd-details text-center'>
                                First User Get Additional 10% Off
                                <p>Apply Coupon Code BARSANAM20 </p>
                                <div className='prdHeading'>
                                    <h2>Organic Cow Milk - Grow Fast-200ml</h2>
                                </div>
                                <div className='ratPrc'>
                                    <div className='rating'>
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                        <FaStar style={{ color: 'tomato' }} />
                                    </div>
                                    <div className='pricesSection'>
                                        <div className='product-price'>
                                            &#8377;1006/

                                        </div>
                                        <div style={{ color: 'red' }}>
                                            <s>1118/</s>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='buyNow'>
                                <a href='https://www.barsanamagic.com/products/details/organic-cow-milk-grow-fast200ml'>
                                    <button> <MdOutlineShoppingCart /> Buy Now</button>
                                </a>

                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>

            </main>


        </>
    )
}

export default ProductSlider;