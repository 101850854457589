import '../milkProduct/milk.css';
import RightImage from '../assets/images/banner-4.png';

import Ribbon from '../assets/images/ribbon.png'
import ProtienOne from '../assets/images/protien_BlackCurrent.jpeg';
import ProtienTwo from '../assets/images/protien_binnla.jpeg';
import ProtienThree from '../assets/images/Protien_chocolate.jpg';
import ProtienFour from '../assets/images/pritien_coffee.jpeg'


import { MdArrowRightAlt } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Swiper, SwiperSlide } from 'swiper/react';

const Milk = () => {

    return (
        <div className="container-fluid">

            <div className="row">
                <div className='p-slider'>
                    <div className='left-image'>
                        <div className='leftSideImage'>
                            <img src={RightImage} />
                        </div>
                        <div className='bannerText'>
                            Bring nature<br />
                            into your<br />
                            home
                        </div>

                        <div className='btn-shop'>
                            <button>Shop Now <MdArrowRightAlt /></button>
                        </div>
                    </div>

                    <div className='right-slider'>
                    <swiper-container breakpoints={
                                JSON.stringify({
                                    486: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },

                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },

                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    }
                                })
                            }
                                navigation-next-el=".custom-next-buttoon"
                                navigation-prev-el=".custom-prev-buttoon"
                                pagination-clickable="true"
                                pagination-dynamic-bullets="true"
                                autoplay-delay="2500"
                                autoplay-disable-on-interaction="false"
                                center-slides="true"
                                showThumbnails="true"
                                showFullscreenButton="true"
                                style={
                                    {
                                        "--swiper-pagination-color": "#3BB77E",
                                        "--swiper-pagination-bullet-size": "15px",
                                    }
                                }>
                            <SwiperSlide>
                                <div className='view-prd'>
                                    <div className='sale'>
                                        <img src={Ribbon}/>
                                        New
                                    </div>
                                    <div className='smallImg'>
                                        <img src={ProtienOne} />
                                    </div>
                                    <div className='prd-details'>

                                        <p>Barsana Magic</p>
                                        <div className='prdHeading'>
                                            <h2>Health Booster Milk Fat Spread</h2>
                                        </div>
                                    </div>

                                    
                                    <div className='buyNow'>
                                        <button> <MdOutlineShoppingCart /> Add to cart</button>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='view-prd'>
                                    <div className='sale'>
                                        New
                                    </div>
                                    <div className='smallImg'>
                                        <img src={ProtienTwo} />
                                    </div>
                                    <div className='prd-details'>
                                        <p>Barsana Magic</p>
                                        <div className='prdHeading'>
                                            <h2>Health Booster Milk Fat Spread</h2>
                                        </div>
                                      
                                    </div>

                                    {/* <div className='product-price'>
                                        &#8377; 500<br />
                                        <progress value={0.5} />
                                    </div> */}
                                    <div className='buyNow'>
                                        <button> <MdOutlineShoppingCart /> Add to cart</button>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='view-prd'>
                                    <div className='sale'>
                                        New
                                    </div>
                                    <div className='smallImg'>
                                        <img src={ProtienThree} />
                                    </div>
                                    <div className='prd-details'>
                                        <p>Barsana Magic</p>
                                        <div className='prdHeading'>
                                            <h2>Health Booster Milk Fat Spread</h2>
                                        </div>
                                       
                                    </div>

                                    {/* <div className='product-price'>
                                        &#8377; 500<br />
                                        <progress value={0.9} />
                                    </div> */}
                                    <div className='buyNow'>
                                        <button> <MdOutlineShoppingCart /> Add to cart</button>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='view-prd'>
                                    <div className='sale'>
                                        New
                                    </div>
                                    <div className='smallImg'>
                                        <img src={ProtienFour} />
                                    </div>
                                    <div className='prd-details'>
                                        <p>Barsana Magic</p>
                                        <div className='prdHeading'>
                                            <h2>Health Booster Milk Fat Spread</h2>
                                        </div>
                                      
                                    </div>

                                    {/* <div className='product-price'>
                                        &#8377; 500<br />
                                        <progress value={0.5} />
                                    </div> */}
                                    <div className='buyNow'>
                                        <button> <MdOutlineShoppingCart /> Add to cart</button>
                                    </div>
                                </div>
                            </SwiperSlide>

                            </swiper-container>


                    </div>
                </div>
            </div>
        </div>
    )
}
export default Milk;