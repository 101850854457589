import React from "react";
import '../parllexImage/parllex.css';



const ParlexImage = () => {
    return (
        <>
            <div class="fixed-bg">
                {/* <h2 className="footerHeading">Healthy and Happy Cows</h2>
                <p className="footerText">Indian breed cows are happily kept and fed organic fodder under organic farm practices.</p> */}
            </div>

        </>
    )
}

export default ParlexImage