import React, { useState } from "react";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Footer from "./component/footer/footer";
import Subscribe from '../src/component/subscribe/subscribe'
import Products from "./component/product/product";
import Milk from "./component/milkProduct/milk";
import AllProduct from "./component/allProduct/allProduct";
import HeroSlider from "./component/heroSection/hero";
import Loading from "./component/loading/loading";

import ProductVideo from "./component/productVideo/productVideo";

import Navbar from "./component/navbar/navbar";


import Product from '../src/component/pages/products';
import Seller from '../src/component/pages/seller';
import Contact from '../src/component/pages/contactus';
import Faqs from '../src/component/pages/faq'
import Blogs from '../src/component/pages/blogs';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProductSlider from "./component/sectionFour/productSlider";
import ParlexImage from "./component/parllexImage/parllex";
import LabReport from "./component/labReport/labReport";
import Certification from "./component/certification/certification";
import Video from "./component/video/video";
import Deal from "./component/proteinShake/deal";
import GiftPack from "./component/gitPack/giftPack";




import Cookies from "universal-cookie";
const cookies=new Cookies();
cookies.set('mycat','Pacman',{path:'/'})
console.log(cookies.get('mycat'));


function App() {
  const [isLoading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false)
  }, 1000);

  return (
    <div className="App">
      {isLoading ? (<Loading />) : (
        <>


            <Navbar />
            <HeroSlider />
            <Certification />
            <ProductVideo />
            {/* <Video/> */}
            <ProductSlider Title="Daily Best Sells" />

            <AllProduct Heading="Featured Categories" />

            <LabReport />
            {/* <Milk /> */}

            <Deal />
            <ParlexImage />
            <Products />
            {/* <GiftPack/> */}
            <Subscribe />
            <Footer />
       
        </>
      )}

    </div>
  );
}

export default App;
