import '../heroSection/hero.css';
import 'animate.css';

import { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import SliderOne from '../assets/images/slider-1-min.png';
import SliderTwo from '../assets/images/slider-2-min.png';
import SliderThree from '../assets/images/slider-3-min.png'
import SliderFour from '../assets/images/spices-pulses.png'
register();

const HeroSlider = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      pagination: true,
      
      // These are new...
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            background-color: white;
            padding: 0px 14px;
            border-radius: 100%;
            
            color: gray;
            width:20px;
          }
          .swiper-pagination-bullet{
            width: 18px;
            height: 18px;
            background-color: #29A56C;
            
          }
      `,
      ],
    };
    
    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);

  return (
    <swiper-container ref={swiperRef} init="false" autoplay-delay="2500">

     <swiper-slide class="blue-slide">
        <img src={SliderOne} alt='' className='imgResTwo'/>
        <div className='sliderText'>
            <p className="animate__animated animate__fadeInTopLeft ">Sudh Dudh Hai, <br/>To <span style={{color:'var(--color-brand)'}}>Swasth<br/> Hai Jeevan  </span></p>
      </div>
      </swiper-slide>
     
     <swiper-slide class="yellow-slide">
        <img src={SliderTwo} alt='' className='imgResTwo'/>
        <div className='sliderText'>
            <p className="animate__animated animate__fadeInTopLeft animate__delay-2s"> Natural and Organic <br/>Honey<br/><span style={{color:'var(--color-brand)'}}>Bliss of Himalayas </span> </p>
      </div>
      </swiper-slide>
      
       

      <swiper-slide class="yellow-slide">
        <img src={SliderThree} alt='' className='imgResTwo'/>
        <div className='sliderText'>
            <p className="animate__animated animate__fadeInTopLeft animate__delay-3s">100 % Organic Cow <br/><span style={{color:'var(--color-brand)'}}>Ghee and Milk <br/>Fat Spreads</span></p>
      </div>
      </swiper-slide>

      {/* <swiper-slide class="yellow-slide">
        <img src={SliderFour} alt='' className='imgResTwo'/>
        <div className='sliderTextFour'>
        <p className="animate__animated animate__fadeInTopLeft animate__delay-3s"> Organic & Pure <br/><span style={{color:'var(--color-brand)'}}>Kitchen Essentials </span></p>
      </div>
       
      </swiper-slide> */}
     
  
    </swiper-container>
  );
};

export default HeroSlider;