import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter as Router ,Routes,Route } from 'react-router-dom';
import Error from './component/404';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  // <BrowserRouter basename="/health-booster">
  //   <Routes>
  //     <Route path="" element={<App/>}>

  //     </Route>
  //   </Routes>
  // </BrowserRouter>

  <Router basename="/">
    <Routes>
    <Route path="*" element={<Error/>}></Route>
    <Route path="/" element={<App />}></Route>
    </Routes>
    
  </Router>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
