import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

import '../certification/certification.css';
import c_one from '../assets/images/11-certificate.webp'
import c_two from '../assets/images/22-certificate.webp'
import c_three from '../assets/images/33-certificate.webp'
import c_four from '../assets/images/44-certificate.webp'
import c_five from '../assets/images/55-certificate.webp'
import c_six from '../assets/images/66-certificate.webp'
import c_seven from '../assets/images/77-certificate.webp'
import c_eight from '../assets/images/88-certificate.webp'


const Certification = () => {
    return (
        <>
            <div className='container-fluid'>
                <div className='cerHeading'>
                    <h2>Certifications</h2>
                </div>
                <div className='row'>
                    <div className='certificationSection'>
                        <img src={c_one} />
                        <img src={c_two} />
                        <img src={c_three} />
                        <img src={c_four} />
                        <img src={c_five} />
                        <img src={c_six} />
                        <img src={c_seven} />
                        <img src={c_eight} />
                    </div>
                </div>
            </div>
            {/* <div className="certificationSlider" breakpoints={
                        JSON.stringify({

                            300: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            486: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            }

                        })
                    }>
                <Swiper 
                    slidesPerView={4}
                    spaceBetween={10}
                    freeMode={true}

                   
                    className="mySwiper">
                    <SwiperSlide><img src={c_one} /></SwiperSlide>
                    <SwiperSlide><img src={c_two} /></SwiperSlide>
                    <SwiperSlide><img src={c_three} /></SwiperSlide>
                    <SwiperSlide><img src={c_four} /></SwiperSlide>
                    <SwiperSlide><img src={c_five} /></SwiperSlide>
                    <SwiperSlide><img src={c_six} /></SwiperSlide>
                    <SwiperSlide><img src={c_seven} /></SwiperSlide>
                    <SwiperSlide><img src={c_eight} /></SwiperSlide>
                </Swiper>
            </div> */}
        </>
    )
}

export default Certification