import './deal.css'
import Ribbon from '../assets/images/ribbon.png'
import ProtienOne from '../assets/images/protien_BlackCurrent.png';
import ProtienTwo from '../assets/images/protien_binnla.png';
import ProtienThree from '../assets/images/Protien_chocolate.png';
import ProtienFour from '../assets/images/pritien_coffee.png'


const Deal = () => {
    return (
        <>
            <div className=''>
                <div className='row'>
                    <div className='deal_section'>
                        <h2>Protein Shake</h2>
                    </div>

                    <div className='protein_shake'>
                        <div className='prt_info'>
                            <div className='card-image'>
                                <img src={ProtienOne} />
                            </div>
                            <div className='proteinHeading'>
                                <h4>Blackcurrent Protein Shake</h4>
                            </div>
                            <div className='text-center'>
                                <a href='https://www.barsanamagic.com/products/details/blackcurrent-dairy-based-certified-organic-protein-shake-180-ml-pack-of-12'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                                </a>
                                
                            </div>
                        </div>

                        <div className='prt_info'>
                            <div className='card-image'>
                                <img src={ProtienTwo} />
                            </div>
                            <div className='proteinHeading'>
                                <h4>Vanilla Protein Shake</h4>
                            </div>
                            <div className='text-center'>
                                <a href='https://www.barsanamagic.com/products/details/vanilla-dairy-based-certified-organic-protein-shake-180-ml-pack-of-12'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                                </a>
                                
                            </div>
                        </div>

                        <div className='prt_info'>
                            <div className='card-image'>
                                <img src={ProtienThree} />
                            </div>
                            <div className='proteinHeading'>
                                <h4> Chocolate Protein Shake</h4>
                            </div>
                            <div className='text-center'>
                                <a href='https://www.barsanamagic.com/products/details/chocolate-dairy-based-certified-organic-protein-shake-200-ml'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                                </a>
                                
                            </div>
                        </div>

                        <div className='prt_info'>
                            <div className='card-image'>
                                <img src={ProtienFour} />
                            </div>
                            <div className='proteinHeading'>
                                <h4>Coffee Protein Shake</h4>
                            </div>
                            <div className='text-center'>
                                <a href='https://www.barsanamagic.com/products/details/coffee-dairy-based-certified-organic-protein-shake-180-ml-pack-of-12'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                                </a>
                                
                            </div>
                        </div>



                        {/* <div className='proteinShake_card'>
                            <div className='imgSection'>
                                <img src={ProtienOne} />
                            </div>
                            <div className='prdDes'>
                                <h4>Blackcurrent Protein Shake</h4>
                            </div>
                            <div className='text-center p-3'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                            </div>
                        </div> */}

                        {/* <div className='proteinShake_card'>
                            <div className='imgSection'>
                                <img src={ProtienTwo} />
                            </div>
                            <div className='prdDes'>
                                <h4>Vanilla Protein Shake</h4>
                            </div>
                            <div className='text-center p-3'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                            </div>
                        </div>

                        <div className='proteinShake_card'>
                            <div className='imgSection'>
                                <img src={ProtienThree} />
                            </div>
                            <div className='prdDes'>
                                <h4>Chocolate Protein Shake</h4>
                            </div>
                            <div className='text-center p-3'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                            </div>
                        </div>

                        <div className='proteinShake_card'>
                            <div className='imgSection'>
                                <img src={ProtienFour} />
                            </div>
                            <div className='prdDes'>
                                <h4>Coffee Protein Shake</h4>
                            </div>
                            <div className='text-center p-3'>
                                <button className='btn btn-outline-success'>Buy Now</button>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Deal;