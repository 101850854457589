import '../product/product.css';
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";


import Badri from '../assets/images/badri.png'
import Bilona from '../assets/images/bilona.png'
import WildForset from '../assets/images/wild-forest.png'
import CowGhee from '../assets/images/organic.png'
import Double from '../assets/images/double-toned.png'
import NeemHoney from '../assets/images/neem.png'
import HealthBooster from '../assets/images/health-booster.png'
import JamunHoney from '../assets/images/jamun.png'
import GrowFast from '../assets/images/grow-fast.png'
import Immunity from '../assets/images/immunity-booster.png'
import Acacia from '../assets/images/acacia.png'
import StayFit from '../assets/images/stay-fit.png'


import Ribbon from '../assets/images/ribbon.png'
import ProtienOne from '../assets/images/protien_BlackCurrent.jpeg';
import ProtienTwo from '../assets/images/protien_binnla.jpeg';
import ProtienThree from '../assets/images/Protien_chocolate.jpg';
import ProtienFour from '../assets/images/pritien_coffee.jpeg'

import ProteinBanner from '../assets/images/banner-5.png'

const Products = () => {
    return (
        <div className='container-fluid'>
            <div className='allProduct'>
                
                <div className='allPro'>
                    <h3>Top Selling</h3>
                   <hr/>
                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={Badri} />
                        </div>

                        <div className='product-text'>
                                <h4>Organic A2 Badri Cow Ghee</h4>
                                <span>
                                <i class="bi bi-star-fill"></i>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}}/>
                                </span>
                                <p>&#8377; 1341</p>
                        </div>
                    </div>
                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={Bilona} />
                        </div>

                        <div className='product-text'>
                                <h4>Organic Bilona Cow Ghee</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 700</p>
                        </div>
                    </div>
                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={WildForset} />
                        </div>

                        <div className='product-text'>
                                <h4>Organic Wild Forest Honey</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 329</p>
                        </div>
                    </div>
                </div>

                <div className='allPro'>
                    <h3>Trending Products</h3>
                    <hr/>
                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={CowGhee} />
                        </div>

                        <div className='product-text'>
                                <h4>Organic Cow Ghee</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 700</p>
                        </div>                      
                    </div>
                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={Double} />
                        </div>

                        <div className='product-text'>
                                <h4>Organic Cow Milk Double Toned -1 L (6)Pack</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 1006</p>
                        </div>
                    </div>
                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={NeemHoney} />
                        </div>

                        <div className='product-text'>
                                <h4>Natural Neem Honey</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 310</p>
                        </div>
                    </div>
                </div>

                <div className='allPro'>
                    <h3>Recently added</h3>
                    <hr/>
                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={HealthBooster} />
                        </div>

                        <div className='product-text'>
                                <h4>Organic Health Booster Milk Fat Spread</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}} />
                                    </span>
                                <p>&#8377; 770</p>
                        </div>
                    </div>

                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={JamunHoney} />
                        </div>

                        <div className='product-text'>
                                <h4>Natural Jamun Honey (Indian Blackberry)</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 310</p>
                        </div>
                    </div>

                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={GrowFast} />
                        </div>

                        <div className='product-text'>
                                <h4>Organic Cow Milk - Grow Fast(200ml)<br/> (24) Pack</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStarHalfAlt style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 1006</p>
                        </div>
                    </div>
                </div>

                <div className='allPro'>
                    <h3>Protein Shake</h3>
                    <hr/>
                    <img src={ProteinBanner}/>
                    {/* <div className='productImages'>
                        <div className='product-image'>
                            <img src={ProtienOne} />
                        </div>

                        <div className='product-text'>
                                <h4>Barsana Magic Organic Cow Milk</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 100</p>
                        </div>
                    </div>

                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={ProtienTwo} />
                        </div>

                        <div className='product-text'>
                                <h4>Barsana Magic Organic Cow Milk</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 100</p>
                        </div>
                    </div>

                    <div className='productImages'>
                        <div className='product-image'>
                            <img src={ProtienThree} />
                        </div>

                        <div className='product-text'>
                                <h4>Barsana Magic Organic Cow Milk</h4>
                                <span>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                    <FaStar style={{color:'tomato'}}/>
                                      <FaStar style={{color:'tomato'}}/>
                                    </span>
                                <p>&#8377; 100</p>
                        </div>
                    </div> */}
                </div>

            </div>
        </div>
    )
}

export default Products