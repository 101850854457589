import erroImage from './assets/images/error.png'
import Navbar from './navbar/navbar';
import Footer from './footer/footer'
const Error=()=>{
    return(
        <>
        <Navbar/>
        <img src={erroImage} style={{width:'100%'}}/>
        <Footer/>
        </>
    )
}

export default Error